import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/Hero"
import { FeatureProject } from "../templates/FeatureProjects"
import styled from "styled-components"

import { Row } from "../components/Row"
import { Column } from "../components/Column"

const FeaturedProjectsSection = styled.section`
  padding-top: 30px;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 1rem;

  @media only screen and (min-width: 75em) {
    margin: auto;
    max-width: 85.2rem;
  }

  @media only screen and (min-width: 62em) {
    margin: auto;
    max-width: 73.2rem;
  }
`
const FeaturedWrapper = styled.div`
  background: #fff;
`

const IndexPage = ({ data }) => {
  const featureProjectOne = data.Projects.edges[0].node.frontmatter
  const featureProjectTwo = data.Projects.edges[1].node.frontmatter

  return (
    <Layout>
      <SEO title="Home" />
      <Hero />
      <FeaturedWrapper>
        <FeaturedProjectsSection
          id="fprojects"
          data-sal="slide-up"
          data-sal-delay="1200"
          data-sal-easing="ease"
        >
          <h2>Featured Projects</h2>
          <Row breakpoints={[850]}>
            <Column widths={[6]}>
              <FeatureProject {...featureProjectOne} />
            </Column>
            <Column widths={[6]}>
              <FeatureProject {...featureProjectTwo} />
            </Column>
          </Row>
          <div className="text-right">
            <Link to="/projects" className="highlighter">
              ALL PROJECTS
            </Link>
          </div>
        </FeaturedProjectsSection>
      </FeaturedWrapper>
    </Layout>
  )
}

export const query = graphql`
  {
    Projects: allMdx(
      sort: { order: DESC, fields: frontmatter___date }
      limit: 2
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            desc
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`

export default IndexPage
