import React from "react"
import styled from "styled-components"
import { OutboundLink } from "gatsby-plugin-google-analytics"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const LinkWrapper = styled.div`
  display: flex;
`

const LinkItem = styled.div`
  padding: 10px;
  color: #363636;
  transition: 0.3s ease;
  opacity: 0.3;

  &:hover {
    opacity: 1;
  }
`

export const OutLinks = () => {
  return (
    <LinkWrapper>
      <OutboundLink
        href="https://github.com/hwb15"
        target="_blank"
        rel="noreferrer"
      >
        <LinkItem>
          <FontAwesomeIcon icon={["fab", "github"]} size="2x" />
        </LinkItem>
      </OutboundLink>
      <OutboundLink
        href="https://www.linkedin.com/in/harry-buchmuller"
        target="_blank"
        rel="noreferrer"
      >
        <LinkItem>
          <FontAwesomeIcon icon={["fab", "linkedin"]} size="2x" />
        </LinkItem>
      </OutboundLink>
      <a href="mailto:harry@hwbuk.me">
        <LinkItem>
          <FontAwesomeIcon icon={"envelope"} size="2x" />
        </LinkItem>
      </a>
    </LinkWrapper>
  )
}
