import React from "react"
import { graphql, Link } from "gatsby"
import { kebabCase } from "lodash"
import Tilt from "react-tilt"
import Img from "gatsby-image/withIEPolyfill"
import "../styles/global.css"

export const FeatureProject = ({ title, featuredImage, desc, path, tags }) => {
  return (
    <div>
      <Link to={path} className="linked" id="path">
        <Tilt options={{ max: 10, scale: 1 }}>
          <Img
            fluid={featuredImage.childImageSharp.fluid}
            objectFit="cover"
            style={{ width: "100%", height: "100%", maxHeight: 260 }}
          />
        </Tilt>
        <h2 className="padding-t-20 margin-b-15">{title}</h2>
        <div className="flex-wrapped">
          {tags.map(tag => (
            <p className="margin-t-5 margin-b-5" key={tag + `tag`}>
              <Link className="p-tag" to={`/tagged/${kebabCase(tag)}/`}>
                {tag.toUpperCase()}
              </Link>
            </p>
          ))}
        </div>
        <p className="margin-t-5">{desc}</p>
      </Link>
    </div>
  )
}

export const query = graphql`
  query Projects {
    allMdx(sort: { order: DESC, fields: frontmatter___date }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            path
            tags
            desc
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
