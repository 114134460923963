import React from "react"
import { OutLinks } from "./OutLinks"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import { Row } from "../components/Row"
import { Column } from "../components/Column"
import styled from "styled-components"
import me from "../../static/hwbparty.png"
import "../utils/fontawesome"

const HeroContainer = styled.div`
  background: #efefef;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 20px;
`

const HeroRow = styled.div`
  padding: 50px 0 0 0;
`

const NameSection = styled.div`
  box-sizing: border-box;
  padding: 0 0.75rem;
  min-height: 1px;
`

const HeroName = styled.h1`
  color: #363636;
  font-size: 40px;
  margin: 0;
  font-weight: 800;
`
const UnderName = styled.div`
  padding: 0;
  margin: 0;
`
const HeroSubName = styled.h2`
  margin-top: 0.83em;
  line-height: 1.6;
  color: #363636;
  font-size: 24px;
  font-weight: 300;
`

const HeroNav = styled.div`
  text-align: center;
`
const HeroImage = styled.div`
  margin-bottom: 0px;
  width: 250px;
  margin: 0 auto;
`

export default () => {
  return (
    <HeroContainer>
      <HeroRow>
        <Row className="reverse">
          <Column widths={[8]}>
            <NameSection
              data-sal="slide-right"
              data-sal-delay="1200"
              data-sal-easing="ease"
            >
              <HeroName>
                Hi, my name is{" "}
                <Link className="foothighlighter" to="#foot">
                  Harry
                </Link>
                .
              </HeroName>
              <UnderName>
                <HeroSubName>
                  I'm a developer and creator based in the UK.
                </HeroSubName>
                <OutLinks />
              </UnderName>
            </NameSection>
          </Column>
          <Column widths={[4]}>
            <HeroImage
              data-sal="slide-up"
              data-sal-delay="1200"
              data-sal-easing="ease"
            >
              <img
                src={me}
                alt="a memoji depecting myself blowing a party horn"
              />
            </HeroImage>
          </Column>
        </Row>
      </HeroRow>
      <HeroNav>
        <AnchorLink href="#fprojects">
          <FontAwesomeIcon icon={"angle-down"} size="2x" color="#363636" />
        </AnchorLink>
      </HeroNav>
    </HeroContainer>
  )
}
